.App {
    text-align: center;
}

.main {
    background: white;
}

.topNav {
    background: white;
    z-index: 999999;
    position: fixed;
    width: 100%;
}

.navbar {
    padding: 0;
    margin: 0;
    z-index: 999;
}

.navbar-brand {
    padding: 0;
    margin: 0;
    /* box-shadow: 0 -2px 9px 2px #633003 */
}
.navbar-expand-lg{
    box-shadow: 0 -2px 9px 2px #633003
}
.navbar>.container-fluid {
    margin: 0;
    padding: 0;
}

.navbar-expand-lg .navbar-collapse {
    flex: inherit;
}

.navbar-nav .nav-item {
    padding: 25px 25px 25px 20px;
}

.navbar-nav .disc {
    background: #633003;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.navbar-nav .nav-item a {
    display: inline-block;
    text-decoration: none;
    text-align: center;
    outline: none;
    text-transform: uppercase;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    float: right;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
}
.mainLogo{
    width: 334px;
}

.mainBanner {
    padding: 0;
    margin: 0;
    position: relative;
    top: 0;
}

.aboutSection {
    padding: 90px 0 90px 0;
}

.bannerImage {
    /* background:url('../images//wide1.jpg'); */
    height: 100vh;

}

.galleryWrapper {
    padding: 150px 0 150px 0;
    background-image: url('./images/galleryBGImg.jpg');
    background-size: cover;
    backdrop-filter: blur(10px);

}

.galleryWrapper::before {
    content: "";
    background: black;
    filter: blur(0);
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.react-photo-album--photo {
    /* border: 10px white solid; */
}

#galleryMain {
    padding: 0 !important

}

.testominalMain {
    background: #f6f6f6;
    padding: 80px;
}

.testominalMain .carousel-control-prev-icon {
    width: 74px;
    height: 124px;
    background-size: cover;
}

.testominalMain .carousel-control-next-icon {
    width: 74px;
    height: 124px;
    background-size: cover;
}

a.right.carousel-control {
    height: 34px;
}

.testiminialContent {
    position: relative !important;
    color: Black !important;
    left: 0 !important;
    right: 0 !important;
    font-family: Arial, Helvetica, sans-serif;
}

.testiminialContent h3 {
    padding: 20px 0 0 0;
    font-style: italic;
    font-weight: 800;
    color: black;
    text-transform: uppercase;
    font-size: 17px;
    font-family: Arial, Helvetica, sans-serif;

}

.testiminialContent p {
    padding: 20px 0 0 0;
    width: 570px;
    margin: 0 auto;
    color: #a1a1a1;
    font-family: Arial, Helvetica, sans-serif;
}

.testimonialImg {
    border-radius: 50% !important;
    width: 150px !important;
    height: 150px !important;
    margin: 0 auto !important;
    display: block;
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.aboutContent {
    padding: 90px 0 0 0;
    visibility: visible;
    /* animation-name:fadeInLeft ; */
    animation: 1s ease-out fadeInLeft 1;
    background-size: cover;
}

.aboutContent h2 {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    margin-top: 0;
    font-size: 18px;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 800;
}

.aboutContent p {
    padding: 10px 40px 0 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.7em;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.aboutImg {
    display: block;
    max-width: 100%;
    height: auto;
}



.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.main-content {
    padding: 100px 0 0 0;
}

.main-home-page {
    background: aquamarine;
}

.top-header {

    background: black;
    color: white;
}

.top-header-main {
    position: fixed;
    width: 100%;
    background: white;
    padding: 0 0px 0;
    z-index: 99999;
}

.top-header-top {
    display: flex;
    vertical-align: middle;


}

.top-menu {
    padding: 5px 0 5px 0;
}

.top-header-top p {
    padding: 0;
    margin: 0;
    position: relative;
    top: 20px;
    font-size: 20px;
}

.top-header-top button {
    background: #0072bd;
    color: white;
    border-radius: 5px;
    PADDING: 10px;
    margin: 10px 10px 10px 20px;

}

.footer-bottom {
    background: #dbcfc5;
    color: #0072bd;
    text-align: center;
    margin: 0 auto;
}

.footerMain {
    background: black;
    color: white;
    text-align: center;
    padding: 16px 0 0 0;
}

.footerMain ul {
    overflow: hidden;
}

.footerMain ul li {

    text-decoration: none;
    list-style: none;
    color: #0072bd;
    /* padding: 20px 0 0 0px; */
    font-size: 20px;
    font-weight: 700;
    float: right;
    margin: 0 10px 0 0px;
}

.footerMain ul li a {
    display: block;
    padding: 5px 0;
    border-radius: 3px;
    transition: all 0.3s ease-in 0s, all 1s ease 0s;
    background: #444749;

}

.footerMain p {
    font-size: 12px;
    color: #fff;
    text-align: left;
    padding-top: 0px;
    margin: 0;
}

.footerMain ul li .instagram:hover {
    background-color: #BA0025;
}

.footerMain ul li .facebook:hover {
    background-color: #365397;
}

.footerMain ul li .linkedin:hover {
    background-color: #006db3;
}

.footerMain ul li .google-plus:hover {
    background-color: #e0452c;
}

.main-Logo {
    height: auto;
    width: 265px;
    padding: 20px 00 0 0;
}

.navbar-brand {
    padding: 0 !important;
}

.navbar-expand {
    padding: 0 !important;
}

.main-menu-item {
    background: no-repeat;
    border: none;
    padding: 20px;
    color: #0072bd;
    font-size: 30px;
}

.about-main{
    margin: 40px 0 0  0;
}
.about-content-pannel {
    margin: 0 0 40px 0;
}
.about-content p{
    font-size: 25px;
 
}
.about-main .aboutContent {
    
}
.about-main .aboutImg{
    
} 
._30i9K img{
    width: 183px;
    display: inline-block !important;
}
.yarl__root.yarl__portal.yarl__no_scroll_padding.yarl__portal_open{
    z-index: 99999 !important;
}
.galleryFilter{
    margin: 19px 0 15px 0px;
}
.filterBtn{
    margin: 0 10px 0 0;
    /* border-radius: 10px; */
    background:#633003;
    border: none;
    color: white;
    padding: 8px;
    box-shadow: 0 3px 10px #633003;
}
.contactUsForm{
    width: 400px;
    margin: 80px auto;
/* font-family: Arial, Helvetica, sans-serif; */
}
.SendEmailForm{
    background-color: #63300333;
    padding: 15px;
    box-shadow: 0 3px 10px #633003;
}
.SendEmailForm input{
margin-bottom: 20px;
}
.SendEmailForm textarea{
    margin-bottom: 20px;
    }
.contactUsForm p {
    font-family: Helvetica;
}
.aboutus{
    padding: 25px 0 0 0;
}
.whatsapp_float{
    
    font-size: 55px;
    color: #25D36A;
    
}
.whatsapp_float_main{
    box-shadow: 0 3px 10px #633003;
    position: fixed;
    bottom: 60px;
    right: 15px;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999;
 
}