
@media only screen and (max-width: 600px) {
    .navbar-expand-lg .navbar-collapse {
        position: absolute;
        top: 102px !important;
        background: #8d6948;
        right: 0px;
        width: 100%;
        color: white;
    }
    .nav-link{
        color: white;
    }
    .aboutSection{
        padding: 30px 0 30px 0;
    }
    .testominalMain{
        padding: 20px;
    }
    .footerMain ul li{
        font-size: 16px;
    }
  }